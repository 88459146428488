import React, { useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import axios from 'axios';
import { getInvoice } from "services/invoice";

const PageInvoice: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const event_id = searchParams.get('event');
  const booth_no = searchParams.get('booth');

  const [statusText, setStatusText] = useState('Loading invoice...');
  useEffect(() => {
    const fetchInvoicePdf = async () => {
      try {
        // Fetch the PDF from your server using Axios
        const response = await getInvoice({
          event_id, booth_no
        });
        // Create a URL for the fetched PDF

        const url = URL.createObjectURL(response);
        setTimeout(()=>{
          setPdfUrl(url);
        }, 1000)
      } catch (error: any) {
        // console.error('Error fetching the invoice PDF:', error);
        // setStatusText( error.message || "Something went wrong!");
        setStatusText( "Please checkout the payment in advance!");
      }
    };

    if (event_id && booth_no) {
      fetchInvoicePdf();
    }
  }, [event_id, booth_no]);

  const handleDownload = () => {
    if (pdfUrl) {
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.download = `Invoice_${event_id}_${booth_no}.pdf`; // Name the file as desired
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className="nc-PageInvoice">
      <Helmet>
        <title>Invoice || Torch Events</title>
      </Helmet>
      <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
        {/* HEADER */}
        <header className="text-center mx-auto space-y-2 mb-5">
          <span className="block text-4xl text-blue-600 sm:text-3xl dark:text-blue-400 tracking-wider font-semibold">
            Payment successful! Thank you for your purchase.
          </span>
          <span className="block text-2xl text-green-500 sm:text-2xl dark:text-blue-400 tracking-wider font-semibold">
            Please look out for your email confirmation
          </span>
        </header>

        <div className="invoice">
          {pdfUrl ? (
            <>
              <iframe
                src={pdfUrl}
                title="Invoice PDF"
                width="100%"
                height="600px"
                style={{ border: 'none' }}
              ></iframe>
              {/* <button
                onClick={handleDownload}
                className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
              >
                Download Invoice
              </button> */}
            </>
          ) : (
            <div className="h-screen">
              <p>{statusText}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageInvoice;
