import React, { FC, useState, useEffect } from "react";
import { useParams, useLocation  } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import ButtonCircle from "shared/Button/ButtonCircle";
import { getExhibitionData } from "services/events";

import ExhibitionList from './ExhibitionList';
import { Applicant } from './types';

const backendUrl =  process.env.REACT_APP_BACKEND_URL;
export interface PageSearchProps {
  className?: string;
}

const PageExhibitors: FC<PageSearchProps> = ({ className = "" }) => {
  const { id }: any = useParams();
  const [applicants, setApplicants] = useState([]);
  const [event, setEvent] = useState({});

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsData = await getExhibitionData(id);
        setApplicants(eventsData.applicants?.sort((a: any, b: any) =>
          a?.company?.name.trim().localeCompare(b.company.name.trim())
        ));

        setEvent(eventsData?.event);
        
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, []);
  return (
    <div className={`nc-PageSearch  ${className}`} data-nc-id="PageSearch">
      <Helmet>
        <title>Search Events || Torch Event</title>
      </Helmet>

      <div className="container py-4 lg:pb-28 lg:pt-8 space-y-8 lg:space-y-4">
        <main>
          {/* FILTER */}
          {/* <HeaderFilterSearchPage /> */}
          <ExhibitionList applicants={ applicants as Applicant[]} event={event}/>
        </main>
        <hr className="border-slate-200 dark:border-slate-700" />
      </div>
    </div>
  );
};

export default PageExhibitors;
