import React, { FC, useState, useEffect } from "react";
import { useParams, useLocation, useSearchParams  } from "react-router-dom";
import FloorPlanKonva from "./FloorPlanKonva";
import { getEventById } from "services/events";

interface EventDetailPageProps {
  className?: string;
}

const EventDetailPage: FC<EventDetailPageProps> = ({ className = "" }) => {
  const [searchParams] = useSearchParams();

  const { id }: any = useParams();// event_id
  const type = searchParams.get('type') || 'map';

  const [exhibitionData, setExhibitionData]: any = useState({
    event: {},
    map: { image: 'uploads/images/noimage.png' },
    applicants: [],
    shapes: [],
  });

  useEffect(() => {
    if(!id) { return; }
    
    getEventById(id || '', type)
      .then((res) => {
        const shapeList = res.shapes.map((shape: any) => {
          const booking = res.applicants.find((applicant: any) => applicant.booth == shape.no);
          return {
            ...shape,
            company_name: booking ? booking.company?.name : '',
          }
        });
        setExhibitionData({
          ...exhibitionData,
          ...res,
          shapes: shapeList,
        });
      })
      .catch((err) => {
        console.error(err.message);
      });
  }, [id]);

  const onHoverListItem = (item: any) => {
    const updatedShapes = exhibitionData.shapes.map((shape: any) => ({
      ...shape,
      selected: item.booth == shape.no,
    }));

    setExhibitionData((prevData: any) => ({
      ...prevData,
      shapes: updatedShapes,
    }));
  };

  return (
    <div className={`nc-EventDetailPage ${className}`} >
      <main className="container mt-5 lg:mt-11">
        <div className="lg:flex">
          <div className="w-full">
            <FloorPlanKonva
              event={exhibitionData.event}
              map={exhibitionData.map}
              shapes={exhibitionData.shapes}
              exhibitors={exhibitionData.applicants.sort((a: any, b: any) =>
                // a.name.localeCompare(b.name)
                a?.company?.name.trim().localeCompare(b.company.name.trim())
              )}
              onHoverListItem={onHoverListItem}
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default EventDetailPage;
