// src/components/Payment.tsx
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
// const stripePromise = loadStripe('your-public-key-here'); // Replace with your actual Stripe public key

import { setBookingData } from '../../store/bookingSlice'; // Import your Redux action
import StripePayment from './StripePayment';
import PayPalPayment from './PaypalPayment';

const Payment: React.FC<{ event: any, booth: any, prevStep: () => void }> = ({ event, booth, prevStep }) => {
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.booking.data);


  // State to manage the selected tab
  const [selectedTab, setSelectedTab] = useState<'stripe' | 'paypal'>(data.payment_method || 'stripe');

  const handleTabClick = (method: 'stripe' | 'paypal') => {
    setSelectedTab(method);
    dispatch(setBookingData({ payment_method: method }));
  };

  return (
    <div className="flex flex-col [height:calc(100%-50px)]">
      <h2 className="text-2xl font-bold mb-4">Payment</h2>

      {/* Payment Method Tabs */}
      <div className="flex border-b mb-4">
        <button
          className={`py-2 px-4 text-sm font-medium ${selectedTab === 'stripe' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
          onClick={() => handleTabClick('stripe')}
        >
          Stripe
        </button>
        <button
          className={`py-2 px-4 text-sm font-medium ${selectedTab === 'paypal' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
          onClick={() => handleTabClick('paypal')}
        >
          PayPal
        </button>
      </div>

      {/* Payment Method Forms */}
      <div className="flex-grow">
        {selectedTab === 'stripe' ? (
          // <Elements stripe={stripePromise}>
          <div className="p-4 border rounded">
            {/* Stripe Payment Form */}
            <StripePayment data={{
              ...data,
              email: data.primary_email,
              event_id: event.id,
              booth_no: booth.no,
              booth_cost: booth.cost,
              tax:  parseFloat(event.tax) 
            }} />
          </div>
          // </Elements>
        ) : (
          <div className="p-4 border rounded">
            {/* PayPal Payment Form */}
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Purchase by PayPal</h2>
            <PayPalPayment data={{
              ...data,
              email: data.primary_email,
              event_id: event.id,
              booth_no: booth.no,
              booth_cost: booth.cost,
              tax:  parseFloat(event.tax) 
            }} />
          </div>
        )}
      </div>
      {/* <p className="text-xl font-semibold mb-4">{event.terms_and_conditions}</p> */}
      {/* Navigation Buttons */}
      <div className="mt-auto flex justify-between p-4 border-t">
        <button className="bg-gray-200 text-gray-600 hover:bg-gray-300 px-4 py-2 rounded" onClick={prevStep}>
          Back
        </button>
        {/* Uncomment this when you have a "Proceed" action */}
        {/* <button className="bg-blue-500 text-white px-4 py-2 rounded">
          Proceed
        </button> */}
      </div>
    </div>
  );
};

export default Payment;
