// src/store/bookingSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface BookingData {
  name: string;// company name
  description: string;// company description
  website_social_media: string;
  logo: string;
  first_name: string;
  last_name: string;
  job_title: string;
  primary_email: string;
  phone: string;
  country: string;
  city: string;
  postcode: string;
  address: string;
  payment_method: 'stripe' | 'paypal' | null;
  terms_and_conditions: string;
}

interface BookingState {
  data: BookingData;
}

const initialState: BookingState = {
  data: {
    name: '',
    description: '',
    website_social_media:'',
    logo: '',
    first_name: '',
    last_name: '',
    job_title: '',
    primary_email: '',
    phone: '',
    country: '',
    city: '',
    postcode: '',
    address: '',
    payment_method: null,
    terms_and_conditions: '',
  },
};

const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    setBookingData: (state, action: PayloadAction<Partial<BookingData>>) => {
      state.data = { ...state.data, ...action.payload };
    },
  },
});

export const { setBookingData } = bookingSlice.actions;
export default bookingSlice.reducer;
