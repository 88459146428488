// src/components/EventDetails.tsx
import React, { FC, useState, useEffect } from 'react';

interface EventDetailsProps {
    event: any;
    booth: any;
}

const EventDetails: FC<EventDetailsProps> = ({ event, booth }) => {

    const [timeLeft, setTimeLeft] = useState<{ days: number; hours: number; minutes: number; seconds: number }>({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });
    const dateTime = new Date().toString();
    const calculateTimeLeft = () => {
        const eventDate = new Date(dateTime).getTime();
        const currentTime = new Date().getTime();
        const difference = eventDate - currentTime;

        let timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        setTimeLeft(timeLeft);
    };

    const getPriceText = () => {
        const net = booth?.cost || 0;
        const tax = (booth?.cost * event?.tax * 0.01).toFixed(2);
        const total = parseFloat(net) + parseFloat(tax);
        const priceText = `£${ total } (net £${net} + tax £${tax})`     
        
        return priceText;
        
    }
    useEffect(() => {
        const timer = setInterval(() => {
            calculateTimeLeft();
        }, 1000);
        return () => clearInterval(timer);
    }, [dateTime]);

    return (
        <div className="h-full p-6 bg-white shadow-md rounded-md">
            {/* Countdown Timer */}
            <div className="flex justify-center items-center my-5">
                <img
                    className="w-24 h-24 object-contain"
                    src={`${process.env.REACT_APP_BACKEND_URL}/${event.image}`}
                    alt={`${event?.full_name} Logo`}
                />
            </div>
            <div className="flex flex-col items-center mb-5">
                <span className="text-3xl font-bold text-green-600">{event.full_name}</span>
            </div>

            {/* Organizer Information */}
            <div className="flex items-center mb-4">
                <div className="bg-green-100 p-3 rounded-full">
                    <i className="fas fa-user text-green-600"></i>
                </div>
                <div className="ml-4">
                    <h3 className="text-lg font-semibold text-green-400">Name</h3>
                    {/* <a href="#" className="text-green-600 text-sm">
                    {event?.contact_name}
                    </a> */}
                    <p className="text-sm text-blue-600">{`${event?.contact_name || 'Unknown'}`}</p>
                </div>
            </div>

            {/* Date and Time */}
            <div className="flex items-center mb-4">
                <div className="bg-green-100 p-3 rounded-full">
                    <i className="fas fa-calendar-alt text-green-600"></i>
                </div>
                <div className="ml-4">
                    <h3 className="text-lg font-semibold text-green-400">Date and Time</h3>
                    <p className="text-sm text-blue-600">{`${event?.start_date} ${event?.time}`}</p>
                </div>
            </div>

            {/* Location */}
            <div className="flex items-center mb-4">
                <div className="bg-green-100 p-3 rounded-full">
                    <i className="fas fa-map-marker-alt text-green-600"></i>
                </div>
                <div className="ml-4">
                    <h3 className="text-lg font-semibold text-green-400">Location</h3>
                    <p className="text-sm text-blue-600">{event?.location || 'Unknown'}</p>
                </div>
            </div>

            {/* Booth No. */}
            <div className="flex items-center mb-4">
                <div className="bg-green-100 p-3 rounded-full">
                    <i className="fas fa-store text-green-600"></i>
                </div>
                <div className="ml-4">
                    <h3 className="text-lg font-semibold text-green-400">Booth No.</h3>
                    <p className="text-sm text-blue-600">{booth?.no || '-'}</p>
                </div>
            </div>
            {/* Booth Size. */}
            <div className="flex items-center mb-4">
                <div className="bg-green-100 p-3 rounded-full">
                    <i className="fas fa-ruler-combined text-green-600"></i>
                </div>
                <div className="ml-4">
                    <h3 className="text-lg font-semibold text-green-400">Booth Size</h3>
                    <p className="text-sm text-blue-600">{`${booth?.booth_size || 'Unknown'}`}</p>
                </div>
            </div>
            {/* Price */}
            <div className="flex items-center">
                <div className="bg-green-100 p-3 rounded-full">
                    <i className="fas fa-dollar-sign text-green-600"></i>
                </div>
                <div className="ml-4">
                    <h3 className="text-lg font-semibold text-green-400">Price</h3>
                    <p className="text-sm text-blue-600">{ getPriceText() }</p>
                </div>
            </div>
        </div>
    );
};

export default EventDetails;
