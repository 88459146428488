import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';

const StyledDialog = styled(Dialog)({
    '& .MuiPaper-root': {
        borderRadius: '16px',
        padding: '24px',
        minWidth: '400px',
        maxWidth: '80%',
        position: 'absolute',
        right: 0,
        top: 0,
    },
});

const ModernButton = styled(Button)({
    borderRadius: '8px',
    padding: '10px 20px',
    fontWeight: 'bold',
    textTransform: 'none',
});


function DialogAvailableBooth({ isDialogOpen, boothInfo, handleCloseDialog }: any) {
    const navigate = useNavigate();

    const handleEnquiry = () => {
        const eventContactEmail = boothInfo.contact_email;
        const eventName = boothInfo.event_fullname;
        const subject = encodeURIComponent(`[${eventName}] enquiry`);
        const body = encodeURIComponent(`Hello,\n\nI have some enquiries regarding the ${eventName}.`);

        window.location.href = `mailto:${eventContactEmail}?subject=${subject}&body=${body}`;
    };

    return (
        <StyledDialog open={isDialogOpen} onClose={handleCloseDialog}>
            <DialogTitle>
                <div className="flex justify-between items-center">
                    <Typography variant="h6" className="flex justify-center items-center font-bold text-blue-900">
                        Booth Details
                    </Typography>
                    <ModernButton
                        variant="outlined"
                        color="primary"
                        onClick={handleCloseDialog}
                    >
                        x
                    </ModernButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="space-y-4">
                    <div className="flex justify-between items-center">
                        <Typography variant="body2" className="font-semibold text-gray-600">
                            Booth No
                        </Typography>
                        <Typography variant="body2" className="text-gray-800">
                            {boothInfo.no}
                        </Typography>
                    </div>
                    <Divider />
                    <div className="flex justify-between items-center">
                        <Typography variant="body2" className="font-semibold text-gray-600">
                            Booth Size
                        </Typography>
                        <Typography variant="body2" className="text-gray-800 font-bold">
                            {`${boothInfo.booth_size}`}
                        </Typography>
                    </div>
                    <Divider />
                    <div className="flex justify-between items-center">
                        <Typography variant="body2" className="font-semibold text-gray-600">
                            Cost
                        </Typography>
                        <Typography variant="body2" className="text-gray-800">
                            £{boothInfo.cost}
                        </Typography>
                    </div>
                </div>
            </DialogContent>
            <DialogActions className="flex justify-between">
                <ModernButton
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        // handle booking action 
                        navigate(`/booking?event=${boothInfo.eventId}&booth=${boothInfo.no}`);
                    }}
                >
                    Book Now
                </ModernButton>
                <ModernButton
                    variant="outlined"
                    color="primary"
                    onClick={handleEnquiry}
                >
                    Enquire
                </ModernButton>
            </DialogActions>
        </StyledDialog>
    );
}

export default DialogAvailableBooth;
